import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
  state: {
    tags: [],
    action: 'all',
    show_editor: false,
    expand: true
  },
  getters: {
    tags: (state) => state.tags,
    expand: (state) => state.expand,
    action: (state) => state.action,
    show_editor: (state) => state.show_editor,
  },
  mutations: {
    update_expand(state, expand) {
      state.expand = expand
    },
    update_tag(state, tags) {
      state.tags = tags
    },
    update_action(state, action) {
      if (action == 'create') {
        state.show_editor = true
        return
      }
      state.action = action

    },
    update_editor(state, show_editor) {
      state.show_editor = show_editor
    }
  }
})

export default store