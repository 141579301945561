import Vue from 'vue'
import root from './root.jsx'
import kui from 'kui-vue'
Vue.config.productionTip = false
Vue.use(kui)
import router from './router'
import store from './store'
import './assets/root.less'
import 'kui-vue/dist/k-ui.css'
import http from './http'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)

Vue.prototype.http = http

new Vue({
  router,
  store,
  render: h => h(root),
}).$mount('#app')
