/**
 * Qiu
 */
// import FileSaver from 'file-saver';
// const XLSX = require('xlsx');
import { Message } from "kui-vue";
import router from './router'
const http = {
  _maps: {},
  cancel: function () {
    for (const id in this._maps) {
      this._maps[id].abort();
      delete this._maps[id]
    }
  },
};
const filter_null = (obj) => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  }
  return obj
}
http._base = (method, base, data) => {
  const controller = new AbortController();
  const key = Date.now() * 1
  http._maps[key] = controller
  return new Promise((res, rej) => {
    let url = base
    let token = localStorage.getItem('token')
    let options = { method: method, signal: controller.signal, headers: { 'Authorization': token } }
    if (method == 'post' || method == 'put') {
      if (data instanceof FormData) {
        options.body = data
      } else if (typeof data === 'object' || Array.isArray(data)) {
        options.headers["Content-Type"] = "application/json"
        options.body = JSON.stringify(data)
      } else {
        options.body = data
      }
    } else {
      if (data) {
        let obj = filter_null(data)
        console.log(obj)
        let { search } = new URL('https://' + url);
        url += (search ? '&' : '?') + new URLSearchParams(obj).toString()
      }
    }
    fetch(url, options).then(r => {
      if (!r.ok && r.status != 401) {
        return new Error(r.status)
      }
      return r.json();
    }).then((data) => {
      if (data instanceof Error) {
        if (data.message == 403) {
          Message.destroy()
          Message.error('登陆失效')
          router.push('/login')
        } else {
          Message.error('网络错误')
        }
        return
      }
      if (data.code == 401) {
        rej(data)
      }
      res(data);
    }).catch(error => {
      // 在这里可以添加自定义的错误处理逻辑
      console.error('Error:', error);
      rej(error);
    }).finally(() => {
      delete http._maps[key]
    })
  })
};


['get', 'post', 'put', 'delete'].forEach(m => {
  http[m] = (url, data) => http._base(m, url, data)
});

export default http