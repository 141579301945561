import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let routes = [
  { path: '/', component: () => import(/*webpackChunkName:'login'*/'./App.jsx') },
  { path: '/login', component: () => import(/*webpackChunkName:'login'*/'./login.jsx') },
]
let router = new VueRouter({
  routes,
  mode: 'history',
  // scrollBehavior(to, from, savedPosition) {
  //   return savedPosition || { x: 0, y: 0 }
  // }

})
export default router
